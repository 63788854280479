import React from "react";
import GameBoardRow from "./GameBoardRow";

function Gameboard(props) {
    const numberOfRows = 6;

    return (
        <div className="gameboard-container">
            <div className="gameboard">
                {Array.from({ length: numberOfRows }, (_, index) => (
                    <GameBoardRow
                        key={index}
                        rowNumber={index +1}
                        currentInput={props.currentInput}
                        gameTileStates={props.gameTileStates.tileStates[index]}
                    />
                ))}
            </div>
        </div>
    )
}

export default Gameboard;