import React from "react";
import Tile from "./Tile";

function GameBoardRow(props) {    
    const numberOfTiles = 5;
    return (
        <div className="row">
            {Array.from({ length: numberOfTiles }, (_, index) => (
                <Tile
                    key={index}
                    letter={props.currentInput.guesses[props.rowNumber - 1][index]}
                    tileState={props.gameTileStates[index]}
                />
            ))}
        </div>
    )
}

export default GameBoardRow;