import React from "react";
import KeyboardRow from "./KeyboardRow";
import { keyboardFirstRow, keyboardSecondRow, keyboardThirdRow } from "../data";
import {v4 as uuidv4 } from "uuid";

function Keyboard(props) {
    const keyboardRows = [keyboardFirstRow, keyboardSecondRow, keyboardThirdRow]

    return (
        <div className="keyboard-container">
            {keyboardRows.map((keyboardRow) => (
                <KeyboardRow key={uuidv4()} guessedLetters={props.guessedLetters} letters={keyboardRow} handleLetterInput={props.handleLetterInput} handleEnter={props.handleEnter} handleBackspace={props.handleBackspace}/>
            ))}
        </div>
    )
}

export default Keyboard;